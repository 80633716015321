// "use strict";

var labelChange = {
    labelChangeFunc: () => {
        function changeLabels(){
            document.querySelectorAll('.o--text-sm').forEach((elm) => {
                if (elm.innerHTML.includes('Upper, lower, and number character')) {
                    elm.innerHTML = 'At least one lowercase, uppercase and numeric character'
                }
                if (elm.innerHTML.includes('Special character')) {
                    elm.innerHTML = 'At least one special character (e.g. !@#$%^&*)'
                }
                if (elm.innerHTML.includes('Not one of your last 2 passwords')) {
                    elm.innerHTML = 'Not one of your recently used passwords'
                }
            })
        }
        //NOTE: i know that this is shit, but our partners demanding this are shittier
        setTimeout(() => {
            changeLabels()
        }, "100");
        setTimeout(() => {
            changeLabels()
        }, "200");
        setTimeout(() => {
            changeLabels()
        }, "300");
        setTimeout(() => {
            changeLabels()
        }, "400");
        setTimeout(() => {
            changeLabels()
        }, "500");
        setTimeout(() => {
            changeLabels()
        }, "600");
        setTimeout(() => {
            changeLabels()
        }, "600");
        setTimeout(() => {
            changeLabels()
        }, "700");
        setTimeout(() => {
            changeLabels()
        }, "800");
        setTimeout(() => {
            changeLabels()
        }, "900");
        setTimeout(() => {
            changeLabels()
        }, "1000");
        setTimeout(() => {
            changeLabels()
        }, "1500");
        setTimeout(() => {
            changeLabels()
        }, "2000");
        setTimeout(() => {
            changeLabels()
        }, "5000");
    }
}

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = { labelChange }
}
